import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Form from 'react-validation/build/form';
import { Row, Button, FormGroup } from 'reactstrap';

import ComponentCard from '../ComponentCard';
import axios from '../../axios/axios';
import InputComponent from './inputs/InputComponent';

const AddEditComponent = ({
  children,
  apiUrl,
  title,
  columns,
  Formvalue,
  setFormvalue,
  setList,
  edit,
  setEdit,
  initialValue,
  reload,
  loading,
  submitFormData,
}) => {
  const headers = {
    headers: {
      Authorization: sessionStorage.getItem('token'),
    },
  };

  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleOnChange = (e) => {
    if (e.target.type === 'checkbox')
      setFormvalue((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
    else if (e.target.type === 'file') {
      if (e.target.multiple)
        return setFormvalue((prev) => ({ ...prev, [e.target.name]: e.target.files }));
      setFormvalue((prev) => ({ ...prev, [e.target.name]: e.target.files[0] }));
    } else setFormvalue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Submit function
  const onSubmit = (_) => {
    if (submitFormData) onSubmitFormData();
    else onSubmitValues();
  };

  // Submit values function
  const onSubmitValues = () => {
    setSubmitLoading(true);
    axios
      .post(`${apiUrl}`, Formvalue, headers)
      .then((res) => {
        if (reload) return window.location.reload();
        setList((prev) => [...prev, res.data?.data]);
        setFormvalue(initialValue);
      })
      .catch((err) => toast.error(err.response?.data?.message))
      .finally((_) => setSubmitLoading(false));
  };

  // Submit FormData function
  const onSubmitFormData = () => {
    setSubmitLoading(true);
    const formData = new FormData();

    for (const key in Formvalue) {
      if (Object.prototype.hasOwnProperty.call(Formvalue, key)) {
        const value = Formvalue[key];
        if (Array.isArray(value || value instanceof FileList)) {
          value.forEach((item, index) => {
            formData.append(`${key}[${index}]`, item);
          });
        } else if (value instanceof File) {
          formData.append(key, value);
        } else if (typeof value === 'object' && value !== null) {
          for (const nestedKey in value) {
            if (Object.prototype.hasOwnProperty.call(value, nestedKey)) {
              formData.append(`${key}[${nestedKey}]`, value[nestedKey]);
            }
          }
        } else {
          formData.append(key, value);
        }
      }
    }

    axios
      .post(`${apiUrl}`, formData, headers)
      .then((res) => {
        if (reload) return window.location.reload();
        setList((prev) => [...prev, res.data?.data]);
        setFormvalue(initialValue);
      })
      .catch((err) => toast.error(err.response?.data?.message))
      .finally((_) => setSubmitLoading(false));
  };

  // Edit function
  const onEditSumbit = (e) => {
    e.preventDefault();
    if (submitFormData) onEditSumbitFormData();
    else onEditSumbitValues();
  };

  // Submit Edit values function
  const onEditSumbitValues = (_) => {
    setSubmitLoading(true);
    axios
      .patch(`${apiUrl}/${Formvalue.id}`, Formvalue, headers)
      .then((_) => {
        setEdit(false);
        setFormvalue(initialValue);
        if (reload) window.location.reload();
        else setList((prev) => prev.map((one) => (one.id === Formvalue.id ? Formvalue : one)));
      })
      .catch((err) => toast.error(err.response?.data?.message))
      .finally((_) => setSubmitLoading(false));
  };

  // Submit Edit FormData function
  const onEditSumbitFormData = (_) => {
    setSubmitLoading(true);
    const formData = new FormData();

    for (const key in Formvalue) {
      if (Object.prototype.hasOwnProperty.call(Formvalue, key)) {
        const value = Formvalue[key];
        // Check the type of value and handle accordingly
        if (Array.isArray(value)) {
          // Handle array values
          value.forEach((item, index) => {
            formData.append(`${key}[${index}]`, item);
          });
        } else if (typeof value === 'object' && value !== null) {
          // Handle nested object values (assuming it's not null)
          for (const nestedKey in value) {
            if (Object.prototype.hasOwnProperty.call(value, nestedKey)) {
              formData.append(`${key}[${nestedKey}]`, value[nestedKey]);
            }
          }
        } else {
          // Handle string, number, etc.
          formData.append(key, value);
        }
      }
    }

    axios
      .patch(`${apiUrl}/${Formvalue.id}`, formData, headers)
      .then((_) => {
        setEdit(false);
        setFormvalue(initialValue);
        if (reload) window.location.reload();
        else setList((prev) => prev.map((one) => (one.id === Formvalue.id ? Formvalue : one)));
      })
      .catch((err) => toast.error(err.response?.data?.message))
      .finally((_) => setSubmitLoading(false));
  };

  // Close Edit function
  const handleCloseEdit = (_) => {
    setEdit(false);
    setFormvalue(initialValue);
  };

  // Reset form data
  const handleReset = () => setFormvalue(initialValue);

  return (
    <ComponentCard title={edit ? `Edit ${title}` : `Add New ${title}`} loading={loading}>
      {/* Form */}
      <Form onSubmit={edit ? onEditSumbit : handleSubmit(onSubmit)}>
        {/* Inputs */}
        <Row>
          {columns &&
            Array.isArray(columns) &&
            columns.length > 0 &&
            columns.map((col, ind) => (
              <InputComponent
                key={ind}
                register={register}
                errors={errors}
                Formvalue={Formvalue}
                handleOnChange={handleOnChange}
                required={true}
                label={col.name}
                name={col.value}
                placeholder={col.placeholder}
                type={col.type}
                options={col.options}
                multiple={col.multiple}
                fullWidth={col.fullWidth}
                disableEdit={edit && col.disableEdit}
              />
            ))}
          {children}
        </Row>
        {/* Buttons */}
        <FormGroup className="d-flex justify-content-end">
          {edit ? (
            <Button className="btn btn-secondary" onClick={handleCloseEdit}>
              <i className="fa-solid fa-close"></i>
            </Button>
          ) : (
            <Button className="btn btn-secondary" onClick={handleReset}>
              <i className="fa-solid fa-undo"></i>
            </Button>
          )}
          <Button color="primary" className="ms-2" type="submit">
            {submitLoading ? <Spinner size="sm" /> : 'Submit'}
          </Button>
        </FormGroup>
      </Form>
    </ComponentCard>
  );
};

export default AddEditComponent;
