import { useEffect, useState } from 'react';
import Table from '../components/common/Table';
import AddEditComponent from '../components/common/AddEditComponent';
import axios from '../axios/axios';
import { toast } from 'react-toastify';

const Packages = () => {
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([]);
  const [edit, setEdit] = useState(false);
  const initialValue = {
    id: 0,
    name: '',
  };



  const [Formvalue, setFormvalue] = useState(initialValue);

  const columns = [
    {
      name: 'Name',
      value: 'name',
      type: 'text',
      placeholder: 'Enter drug name',
    },
  ];


  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      uploadFile(selectedFile);
    }
  };


  async function uploadFile(file) {
    setLoading(true)
    try {
      const formData = new FormData();
      formData.append('file', file);
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: sessionStorage.getItem('token'),
        },
      };

      const response = await axios.post(`/drug/import`, formData, headers);
      toast.success(response?.data?.message);
      setLoading(false)
      window.location.reload();
    } catch (err) {
      toast.error(err?.response?.data.message);
      setLoading(false)
    }
  }


  return (
    <>
      {/* Add New drug */}
      <div>
      <AddEditComponent
        apiUrl={'drug'}
        title={'Drug'}
        initialValue={initialValue}
        columns={columns}
        Formvalue={Formvalue}
        setFormvalue={setFormvalue}
        setList={setList}
        edit={edit}
        setEdit={setEdit}
      />
      <div className='d-flex justify-content-end me-4 '>
        
      <label className='text-end btn btn-danger' htmlFor='download'>
        {loading ? <i className="fa-solid fa-spinner fa-spin fa-spin-reverse"></i> :  <i className="fa-solid fa-file-arrow-down"></i>}
       
      </label>
      <input type="file" id='download'  accept=".xls,.xlsx" onChange={handleFileChange}  className='d-none'/>
      </div>

      </div>




      {/* Drigs List */}
      <Table
        apiUrl={'drug'}
        title={'Drug'}
        columns={columns}
        list={list}
        setList={setList}
        allowDelete={true}
        allowEdit={true}
        Formvalue={Formvalue}
        setFormvalue={setFormvalue}
        setEdit={setEdit}
      />
    </>
  );
};

export default Packages;
