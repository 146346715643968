import { useState } from 'react';
import Table from '../components/common/Table';
import AddEditComponent from '../components/common/AddEditComponent';

const PermissionGroups = () => {
  const [list, setList] = useState([]);
  const [edit, setEdit] = useState(false);

  const initialValue = {
    id: 0,
    name: '',
    drug_add: false,
    drug_edit: false,
    drug_delete: false,
    specialization_add: false,
    specialization_edit: false,
    specialization_delete: false,
    package_add: false,
    package_edit: false,
    package_delete: false,
    clinic_add: false,
    clinic_edit: false,
    clinic_delete: false,
  };

  const [Formvalue, setFormvalue] = useState(initialValue);

  const columns = [
    {
      name: 'Name',
      value: 'name',
      type: 'text',
      placeholder: 'Enter name',
      fullWidth: true,
    },
    {
      name: 'Add Drug',
      value: 'drug_add',
      type: 'checkbox',
    },
    {
      name: 'Edit Drug',
      value: 'drug_edit',
      type: 'checkbox',
    },
    {
      name: 'Delete Drug',
      value: 'drug_delete',
      type: 'checkbox',
    },
    {
      name: 'Add Specialization',
      value: 'specialization_add',
      type: 'checkbox',
    },
    {
      name: 'Edit Specialization',
      value: 'specialization_edit',
      type: 'checkbox',
    },
    {
      name: 'Delete Specialization',
      value: 'specialization_delete',
      type: 'checkbox',
    },
    {
      name: 'Add Package',
      value: 'package_add',
      type: 'checkbox',
    },
    {
      name: 'Edit Package',
      value: 'package_edit',
      type: 'checkbox',
    },
    {
      name: 'Delete Package',
      value: 'package_delete',
      type: 'checkbox',
    },
    {
      name: 'Add Clinic',
      value: 'clinic_add',
      type: 'checkbox',
    },
    {
      name: 'Edit Clinic',
      value: 'clinic_edit',
      type: 'checkbox',
    },
    {
      name: 'Delete Clinic',
      value: 'clinic_delete',
      type: 'checkbox',
    },
  ];

  const tableColumns = [
    {
      name: 'Name',
      value: 'name',
    },
  ];

  return (
    <>
      {/* Add New permission group */}
      <AddEditComponent
        apiUrl={'permissionGroups'}
        title={'Permission Group'}
        initialValue={initialValue}
        columns={columns}
        Formvalue={Formvalue}
        setFormvalue={setFormvalue}
        setList={setList}
        edit={edit}
        setEdit={setEdit}
      >
        {/* {columns.map((col, ind) => (
          <Col key={ind} sm="12" md="6" className="d-flex">
            <div className="form-check form-check-inline me-0">
              <Input
                type="checkbox"
                id="exampleCustomCheckbox1"
                name={col.value}
                value={Formvalue[col.name]}
                onChange={handleCheckBox}
              />
            </div>
            <Label className="control-Label ms-0" htmlFor={col.name}>
              {col.name}
            </Label>
          </Col>
        ))} */}
      </AddEditComponent>
      {/* Permission Groups List */}
      <Table
        apiUrl={'permissionGroups'}
        lang={'both'}
        title={'Permission Groups'}
        columns={tableColumns}
        list={list}
        setList={setList}
        allowDelete={true}
        allowEdit={true}
        Formvalue={Formvalue}
        setFormvalue={setFormvalue}
        setEdit={setEdit}
      />
    </>
  );
};

export default PermissionGroups;
