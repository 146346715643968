import { useState } from 'react';
import Table from '../components/common/Table';
import AddEditComponent from '../components/common/AddEditComponent';

const PaymentMethods = () => {
  const [list, setList] = useState([]);
  const [edit, setEdit] = useState(false);

  const initialValue = {
    id: 0,
    name_en: '',
    name_ar: '',
  };

  const [Formvalue, setFormvalue] = useState(initialValue);

  const columns = [
    {
      name: 'English Name',
      value: 'name_en',
      type: 'text',
      placeholder: 'Enter english name'
    },
    {
      name: 'Arabic Name',
      value: 'name_ar',
      type: 'text',
      placeholder: 'Enter arabic name'
    },
  ];

  return (
    <>
      {/* Add New Specialization */}
      <AddEditComponent
        apiUrl={'paymentmethod'}
        title={'Payment Method'}
        initialValue={initialValue}
        columns={columns}
        Formvalue={Formvalue}
        setFormvalue={setFormvalue}
        setList={setList}
        edit={edit}
        setEdit={setEdit}
      />
      {/* Specializations List */}
      <Table
        apiUrl={'paymentmethod'}
        lang={'both'}
        title={'Payment Method'}
        columns={columns}
        list={list}
        setList={setList}
        allowDelete={true}
        allowEdit={true}
        Formvalue={Formvalue}
        setFormvalue={setFormvalue}
        setEdit={setEdit}
      />
    </>
  );
};

export default PaymentMethods;
