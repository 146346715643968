import { useEffect, useState } from 'react';
import { Spinner, Table as List } from 'reactstrap';
import { Button } from 'reactstrap';

import ComponentCard from '../ComponentCard';
import axios from '../../axios/axios';
import { toast } from 'react-toastify';
import { Pagination } from '@mui/material';

const Table = ({
  apiUrl,
  lang,
  title,
  columns,
  list,
  setList,
  allowEdit,
  allowDelete,
  Formvalue,
  setFormvalue,
  setEdit,
  allowExtraBtn,
  extraBtnFn,
  extraBtnIcon,
}) => {
  const headers = {
    headers: {
      Authorization: sessionStorage.getItem('token'),
    },
  };

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // Get Data useEffect
  useEffect(
    (_) => {
      setLoading(true);
      axios
        .get(`${apiUrl}?lang=${lang || 'en'}&page=${page}&perPage=${perPage}`, headers)
        .then((res) => {
          setList(res.data?.data);
          setPageCount(res.data?.pagination?.totalPages || 1);
        })
        .catch((err) => toast.error(err.response?.data?.message))
        .finally((_) => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiUrl, lang, page, perPage],
  );

  // Edit function
  const handleOnEdit = (data) => {
    setEdit(true);
    setFormvalue(data);
  };

  // Delete function
  const onDelete = (id) => {
    setDeleteLoading(true);
    axios
      .delete(`${apiUrl}/${id}`, headers)
      .then((_) => setList((prev) => prev.filter((one) => one.id !== id)))
      .catch((err) => toast.error(err.response?.data?.message))
      .finally((_) => setDeleteLoading(false));
  };

  // // On page click
  const onChangePagination = (_, value) => setPage(value);

  return (
    <ComponentCard
      title={title + ' List'}
      bodyClasses="pt-0"
      perPage={perPage}
      setPerPage={setPerPage}
    >
      <List responsive>
        {/* Header => Columns names */}
        <thead>
          <tr>
            <th>#</th>
            {columns &&
              Array.isArray(columns) &&
              columns.length > 0 &&
              columns.map((col, ind) => <th key={ind}>{col.name}</th>)}
            {(allowDelete || allowEdit) && <th>Actions</th>}
          </tr>
        </thead>
        {/* Body */}
        <tbody>
          {list &&
            Array.isArray(list) &&
            list.length > 0 &&
            list.map((one, ind) => (
              // Col
              <tr key={ind}>
                <th scope="row">{ind + 1}</th>
                {columns &&
                  Array.isArray(columns) &&
                  columns.length > 0 &&
                  columns.map((col, ind) => {
                    // Image
                    if (col.tableType === 'image')
                      return (
                        <td key={ind}>
                          <img
                            src={one[col.value]}
                            style={{ width: '70px', height: '10vh', borderRadius: '50px' }}
                          />
                        </td>
                      );
                    // Url
                    else if (col.tableType === 'url') {
                      let url = '';
                      if (col.preUrl) url = col.preUrl;
                      if (col.value && col.subValue && one[col.value][col.subValue])
                        url += one[col.value][col.subValue];
                      else if (col.value && one[col.value]) url += one[col.value];
                      if (col.postUrl) url += col.preUrl;
                      return (
                        <td key={ind}>
                          <a
                            href={url}
                            target={col.openInNewWindow ? '_blank' : '_self'}
                            rel="noreferrer"
                          >
                            {col.linkLabel} <i className="fa-solid fa-up-right-from-square"></i>
                          </a>
                        </td>
                      );
                    }
                    return (
                      <td key={ind}>
                        {col.value && col.subValue ? one[col.value][col.subValue] : one[col.value]}
                      </td>
                    );
                  })}
                {/* Actions */}
                <td>
                  {/* extra Btn */}
                  {allowExtraBtn && (
                    <Button color="primary" onClick={() => extraBtnFn(one)}>
                      <i className={extraBtnIcon || 'fa-solid fa-eye'}></i>
                    </Button>
                  )}
                  {/* Edit Btn */}
                  {allowEdit && (
                    <Button color="primary" onClick={(_) => handleOnEdit(one)}>
                      <i className="fa-solid fa-edit"></i>
                    </Button>
                  )}
                  {/* Delete Btn */}
                  {allowDelete && (
                    <Button className="btn btn-danger mx-1" onClick={(_) => onDelete(one.id)}>
                      {deleteLoading && Formvalue.id === one.id ? (
                        <Spinner size="sm" />
                      ) : (
                        <i className="fa-solid fa-trash"></i>
                      )}
                    </Button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </List>
      {/* Table loading */}
      {loading && <p className="pt-3 text-center">Loading...</p>}
      {/* Pagination */}
      {pageCount && pageCount > 1 && (
        <div className="d-flex justify-content-center">
          <Pagination
            count={pageCount}
            defaultPage={1}
            page={page}
            shape="rounded"
            color="primary"
            onChange={onChangePagination}
          />
        </div>
      )}
    </ComponentCard>
  );
};

export default Table;
