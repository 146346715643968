import { Card, CardBody, CardTitle, CardSubtitle, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const ComponentCard = ({
  children,
  title,
  subtitle,
  bodyClasses,
  loading,
  perPage,
  setPerPage,
}) => {
  return (
    <Card>
      <CardTitle
        tag="h4"
        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
      >
        {title}
        {loading ? <Spinner size={'sm'} className="ms-2" /> : ''}
        {perPage && setPerPage && (
          <div className="d-flex align-items-center gap-2 fs-5" style={{ fontWeight: '400' }}>
            Rows
            <select
              type={'select'}
              value={perPage}
              onChange={(e) => setPerPage(e.target.value)}
              className="form-control"
              style={{ width: '50px' }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        )}
      </CardTitle>
      <CardBody className={'p-4 ' + bodyClasses}>
        <CardSubtitle className="text-muted mb-3">{subtitle || ''}</CardSubtitle>
        <div>{children}</div>
      </CardBody>
    </Card>
  );
};

ComponentCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.node,
};

export default ComponentCard;
