import { useEffect, useState } from 'react';
import AddEditComponent from '../components/common/AddEditComponent';
import axios from '../axios/axios';
import { toast } from 'react-toastify';
import ComponentCard from '../components/ComponentCard';
import { Button, Table as List, Spinner } from 'reactstrap';
import { Pagination } from '@mui/material';

const Conflicts = () => {
  const headers = {
    headers: {
      Authorization: sessionStorage.getItem('token'),
    },
  };

  const initialValue = {
    id: 0,
    drug1: null,
    drug2: null,
  };

  const [Formvalue, setFormvalue] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [list, setList] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // Get drugs data
  useEffect((_) => {
    setLoading(true);
    axios
      .get(`drug`, headers)
      .then((res) =>
        setSelectOptions(res.data?.data.map((one) => ({ label: one.name, value: one.id }))),
      )
      .catch((err) => toast.error(err.response?.data?.message))
      .finally((_) => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: 'Drug 1 Name',
      value: 'drug1',
      type: 'select',
      placeholder: 'Select a drug name',
      options: selectOptions.filter((one) => one.value !== parseInt(Formvalue.drug2)),
    },
    {
      name: 'Drug 2 Name',
      value: 'drug2',
      type: 'select',
      placeholder: 'Select a drug name',
      options: selectOptions.filter((one) => one.value !== parseInt(Formvalue.drug1)),
    },
  ];

  // Get Data useEffect
  useEffect(
    (_) => {
      setLoading(true);
      axios
        .get(`conflict?lang=en&page=${page}&perPage=${perPage}`, headers)
        .then((res) => {
          setList(res.data?.data);
          setPageCount(res.data?.pagination?.totalPages || 1);
        })
        .catch((err) => toast.error(err.response?.data?.message))
        .finally((_) => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, perPage],
  );

  // Delete function
  const onDelete = (drug1id, drug2id) => {
    setDeleteLoading(true);
    axios
      .delete(`conflict/${drug1id}/${drug2id}`, headers)
      .then((_) =>
        setList((prev) => prev.filter((one) => one.drug1id !== drug1id && one.drug2id !== drug2id)),
      )
      .catch((err) => toast.error(err.response?.data?.message))
      .finally((_) => setDeleteLoading(false));
  };

  // // On page click
  const onChangePagination = (_, value) => setPage(value);

  

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      uploadFile(selectedFile);
    }
  };


  async function uploadFile(file) {
    setLoadingDownload(true)
    try {
      const formData = new FormData();
      formData.append('file', file);

      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: sessionStorage.getItem('token'),
        },
      };

      const response = await axios.post(`/drug/importConflict`, formData, headers);
      toast.success(response?.data?.message);
      setLoadingDownload(false)
    } catch (err) {
      toast.error(err?.response?.data.message);
      setLoadingDownload(false)

    }
  }
  return (
    <>
      {/* Add New conflict */}
      <AddEditComponent
        apiUrl={'conflict'}
        title={'Conflict'}
        initialValue={initialValue}
        columns={columns}
        Formvalue={Formvalue}
        setFormvalue={setFormvalue}
        setList={setList}
        edit={edit}
        setEdit={setEdit}
        reload={true}
        loading={loading}
      />
      

      <div className='d-flex justify-content-end me-4 '>
        
      <label className='text-end btn btn-danger' htmlFor='download'>
        {loadingDownload ? <i className="fa-solid fa-spinner fa-spin fa-spin-reverse"></i> :  <i className="fa-solid fa-file-arrow-down"></i>}
       
      </label>
      <input type="file" id='download'  accept=".xls,.xlsx" onChange={handleFileChange}  className='d-none'/>
      </div>





      {/* Conflicts List */}
      <ComponentCard
        title={'Conflict List'}
        bodyClasses="pt-0"
        perPage={perPage}
        setPerPage={setPerPage}
      >
        <List responsive>
          {/* Head */}
          <thead>
            <tr>
              <th>#</th>
              {columns &&
                Array.isArray(columns) &&
                columns.length > 0 &&
                columns.map((col, ind) => <th key={ind}>{col.name}</th>)}
              <th>Actions</th>
            </tr>
          </thead>
          {/* Body */}
          <tbody>
            {list &&
              Array.isArray(list) &&
              list.length > 0 &&
              list.map((one, ind) => (
                <tr key={ind}>
                  <th scope="row">{ind + 1}</th>
                  {columns &&
                    Array.isArray(columns) &&
                    columns.length > 0 &&
                    columns.map((col, ind) => {
                      // Image
                      if (col.tableType === 'image')
                        return (
                          <td key={ind}>
                            <img src={one[col.value]} style={{ width: '5vw' }} />
                          </td>
                        );
                      // URL
                      else if (col.tableType === 'url') {
                        let url = '';
                        if (col.preUrl) url = col.preUrl;
                        if (col.value && col.subValue && one[col.value][col.subValue])
                          url += one[col.value][col.subValue];
                        else if (col.value && one[col.value]) url += one[col.value];
                        if (col.postUrl) url += col.preUrl;
                        return (
                          <td key={ind}>
                            <a
                              href={url}
                              target={col.openInNewWindow ? '_blank' : '_self'}
                              rel="noreferrer"
                            >
                              {col.linkLabel} <i className="fa-solid fa-up-right-from-square"></i>
                            </a>
                          </td>
                        );
                      }
                      // Else
                      return (
                        <td key={ind}>
                          {col.value && col.subValue
                            ? one[col.value][col.subValue]
                            : one[col.value]}
                        </td>
                      );
                    })}
                  {/* Actions */}
                  <td>
                    <Button
                      className="btn btn-danger mx-1"
                      onClick={(_) => onDelete(one.drug1id, one.drug2id)}
                    >
                      {deleteLoading && Formvalue.id === one.id ? (
                        <Spinner size="sm" />
                      ) : (
                        <i className="fa-solid fa-trash"></i>
                      )}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </List>
        {/* Loading */}
        {loading && <p className="pt-3 text-center">Loading...</p>}
        {/* Pagination */}
        {pageCount && pageCount > 1 && (
          <div className="d-flex justify-content-center">
            <Pagination
              count={pageCount}
              defaultPage={1}
              page={page}
              shape="rounded"
              color="primary"
              onChange={onChangePagination}
            />
          </div>
        )}
      </ComponentCard>
    </>
  );
};

export default Conflicts;
