import { useState } from 'react';
import Table from '../components/common/Table';
import AddEditComponent from '../components/common/AddEditComponent';

const Packages = () => {
  const [list, setList] = useState([]);
  const [edit, setEdit] = useState(false);

  const initialValue = {
    id: 0,
    name: '',
    duration: null,
    price: null,
    no_of_staff: null,
    no_of_branches: null,
    no_of_doctors: null,
  };

  const [Formvalue, setFormvalue] = useState(initialValue);
  const [errors, setErrors] = useState({});

  const validate = (values) => {
    const validations = [
      { field: 'name', rule: (value) => !!value, message: 'Name is required' },
      {
        field: 'duration',
        rule: (value) => value !== null && value !== 0,
        message: 'Duration must be greater than zero',
      },
      {
        field: 'price',
        rule: (value) => value === null || value >= 0,
        message: 'Price is required',
      },
      {
        field: 'no_of_staff',
        rule: (value) => value === null || value >= 0,
        message: 'Number of staff is required',
      },
      {
        field: 'no_of_branches',
        rule: (value) => value === null || value >= 0,
        message: 'Number of branches is required',
      },
      {
        field: 'no_of_doctors',
        rule: (value) => value === null || value >= 0,
        message: 'Number of doctors is required',
      },
    ];

    return validations
      .filter(
        (validation) =>
          values[validation.field] === null || !validation.rule(values[validation.field]),
      )
      .reduce((acc, validation) => ({ ...acc, [validation.field]: validation.message }), {});
  };

  const columns = [
    {
      name: 'Name',
      value: 'name',
      type: 'text',
      placeholder: 'Enter name',
      required: true,
      error: errors.name,
    },
    {
      name: 'Duration',
      value: 'duration',
      type: 'number',
      placeholder: 'Enter duration',
      required: true,
      error: errors.duration,
    },
    {
      name: 'Price',
      value: 'price',
      type: 'number',
      placeholder: 'Enter price',
      required: true,
      errors: errors.price,
    },
    {
      name: 'Number of stuff',
      value: 'no_of_staff',
      type: 'number',
      placeholder: 'Enter number of stuff',
      required: true,
      error: errors.no_of_staff,
    },
    {
      name: 'Number of branches',
      value: 'no_of_branches',
      type: 'number',
      placeholder: 'Enter number of branches',
      required: true,
      error: errors.no_of_branches,
    },
    {
      name: 'Number of Doctors',
      value: 'no_of_doctors',
      type: 'number',
      placeholder: 'Enter number of Doctors',
      required: true,
      error: errors.no_of_doctors,
    },
  ];

  return (
    <>
      {/* Add New Package */}
      <AddEditComponent
        apiUrl={'package'}
        title={'Package'}
        initialValue={initialValue}
        columns={columns}
        Formvalue={Formvalue}
        setFormvalue={setFormvalue}
        setList={setList}
        edit={edit}
        setEdit={setEdit}
      />
      {/* Packages List */}
      <Table
        apiUrl={'package'}
        title={'Package'}
        columns={columns}
        list={list}
        setList={setList}
        allowDelete={true}
        allowEdit={true}
        Formvalue={Formvalue}
        setFormvalue={setFormvalue}
        setEdit={setEdit}
      />
    </>
  );
};

export default Packages;
