import { Col, FormGroup, Label } from 'reactstrap';

const Select = ({
  register,
  errors,
  Formvalue,
  handleOnChange,
  required,
  label,
  placeholder,
  name,
  type,
  options,
  fullWidth,
  disableEdit
}) => {
  return (
    <Col sm="12" md={fullWidth ? '12' : '6'}>
      <FormGroup>
        <Label className="control-Label" htmlFor={name}>
          {label}
          {required && ' *'}
        </Label>
        <div className="mb-2">
          <select
            type={'select'}
            {...register(name, { required: required })}
            value={Formvalue[name] || ''}
            onChange={handleOnChange}
            className="form-control"
            disabled={disableEdit}
          >
            <option value="" disabled>
              {placeholder || 'select'}
            </option>
            {type === 'select' &&
              options &&
              Array.isArray(options) &&
              options.map((opt, ind) => (
                <option key={ind} value={opt.value}>
                  {opt.label}
                </option>
              ))}
          </select>
        </div>
        <span className="text-danger">{errors[name] && `${label} is required.`}</span>
      </FormGroup>
    </Col>
  );
};

export default Select;
