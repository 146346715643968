import { useEffect, useState } from 'react';
import Table from '../components/common/Table';
import AddEditComponent from '../components/common/AddEditComponent';
import axios from '../axios/axios';
import { toast } from 'react-toastify';

const Admins = () => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [edit, setEdit] = useState(false);

  const headers = {
    headers: {
      Authorization: sessionStorage.getItem('token'),
    },
  };

  const initialValue = {
    id: 0,
    name: '',
    email: '',
    permission_group: null,
  };

    // Get drugs data
    useEffect((_) => {
      setLoading(true);
      axios
        .get(`permissionGroups`, headers)
        .then((res) =>
          setSelectOptions(res.data?.data.map((one) => ({ label: one.name, value: one.id }))),
        )
        .catch((err) => toast.error(err.response?.data?.message))
        .finally((_) => setLoading(false));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const [Formvalue, setFormvalue] = useState(initialValue);

  const columns = [
    {
      name: 'Name',
      value: 'name',
      type: 'text',
      placeholder: 'Enter name',
      disableEdit: true
    },
    {
      name: 'Email',
      value: 'email',
      type: 'email',
      placeholder: 'Enter email address',
      disableEdit: true
    },
    {
      name: 'Permission Group',
      value: 'permission_group',
      type: 'select',
      placeholder: 'Select a permission group',
      options: selectOptions,
    },
  ];

  const tableColumns = [
    {
      name: 'Name',
      value: 'name',
    },
    {
      name: 'Email',
      value: 'email',
    },
    {
      name: 'Permission Group',
      value: 'permission_group_name',
    },
  ];

  return (
    <>
      {/* Add New admin */}
      <AddEditComponent
        apiUrl={'superadmins'}
        title={'Admin'}
        initialValue={initialValue}
        columns={columns}
        Formvalue={Formvalue}
        setFormvalue={setFormvalue}
        setList={setList}
        edit={edit}
        setEdit={setEdit}
        reload={true}
        loading={loading}
      />
      {/* Admins List */}
      <Table
        apiUrl={'superadmins'}
        title={'Admins'}
        columns={tableColumns}
        list={list}
        setList={setList}
        allowDelete={true}
        allowEdit={true}
        Formvalue={Formvalue}
        setFormvalue={setFormvalue}
        setEdit={setEdit}
      />
    </>
  );
};

export default Admins;
