import Select from './Select';
import CheckBox from './CheckBox';
import TextBox from './TextBox';

// type: select | checkbox | text | password | tel | email | url | number | datetime-local | date | time | week | month | color | range
const InputComponent = ({
  register,
  errors,
  Formvalue,
  handleOnChange,
  required,
  label,
  placeholder,
  name,
  type,
  options,
  multiple,
  fullWidth,
  disableEdit
}) => {
  return type === 'select' ? (
    <Select
    register={register}
    errors={errors}
    Formvalue={Formvalue}
    handleOnChange={handleOnChange}
    required={required}
    label={label}
    placeholder={placeholder}
    name={name}
    type={type}
    options={options}
    fullWidth={fullWidth}
    disableEdit={disableEdit}
    />
  ) : type === "checkbox" ? (
    <CheckBox
    Formvalue={Formvalue}
    handleOnChange={handleOnChange}
    label={label}
    name={name}
    fullWidth={fullWidth}
    disableEdit={disableEdit}
    />
  ) : (
    <TextBox
    register={register}
    errors={errors}
    Formvalue={Formvalue}
    handleOnChange={handleOnChange}
    required={required}
    label={label}
    placeholder={placeholder}
    name={name}
    type={type}
    multiple={multiple}
    fullWidth={fullWidth}
    disableEdit={disableEdit}
    />
  );
};

export default InputComponent;
