import { Col, FormGroup, Label } from 'reactstrap';

const TextBox = ({
  register,
  errors,
  Formvalue,
  handleOnChange,
  required,
  label,
  placeholder,
  name,
  type,
  multiple,
  fullWidth,
  disableEdit
}) => {
  return (
    <Col sm="12" md={fullWidth ? '12' : '6'}>
      <FormGroup>
        <Label className="control-Label" htmlFor={name}>
          {label}
          {required && ' *'}
        </Label>
        <div className="mb-2">
          <input
            type={type || 'text'}
            {...register(name, { required: required })}
            value={type !== "file" ? Formvalue[name] : undefined}
            onChange={handleOnChange}
            placeholder={placeholder}
            className="form-control"
            multiple={multiple}
            disabled={disableEdit}
          />
        </div>
        <span className="text-danger">{errors[name] && `${label} is required.`}</span>
      </FormGroup>
    </Col>
  );
};

export default TextBox;
