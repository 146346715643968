import { Col, Input, Label } from 'reactstrap';

const CheckBox = ({
  Formvalue,
  handleOnChange,
  label,
  name,
  fullWidth,
  disableEdit
}) => {
  return (
    <Col sm="12" md={fullWidth ? '12' : '6'} className="d-flex">
      <div className="form-check form-check-inline me-0">
        <Input
          type="checkbox"
          id="exampleCustomCheckbox1"
          name={name}
          value={Formvalue[name]}
          checked={Formvalue[name]}
          onChange={handleOnChange}
          disabled={disableEdit}
        />
      </div>
      <Label className="control-Label ms-0" htmlFor={name}>
        {label}
      </Label>
    </Col>
  );
};

export default CheckBox;
