import { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from 'reactstrap';

import female from '../assets/images/users/user1.jpg';
import male from '../assets/images/users/user4.jpg';

import { toast } from 'react-toastify';
import axios from '../axios/axios';

const Profile = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const [activeTab, setActiveTab] = useState('2');
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(user.name || '');
  const [changePswdData, setChangePswdData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const headers = {
    headers: {
      Authorization: sessionStorage.getItem('token'),
    },
  };

  const handleOnChangePswdData = (e) => {
    setChangePswdData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSubmitChangePassword = (e) => {
    e.preventDefault();
    if (changePswdData.newPassword !== changePswdData.confirmPassword)
      return toast.error("New password & confirm password don't match");
    if (changePswdData.oldPassword === changePswdData.newPassword)
      return toast.error("Your new password could not be your old one");
    setLoading(true);
    axios
      .patch('superadmins/changepassword', changePswdData, headers)
      .then((res) => {
        toast.success(res.data?.message);
        sessionStorage.clear();
        window.location.replace('/login');
      })
      .catch((err) => toast.error(err.response.data?.message))
      .finally((_) => setLoading(false));
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch('superadmins/profile', { name }, headers)
      .then((res) => {
        toast.success(res.data?.message);
        sessionStorage.setItem('user', JSON.stringify(res.data?.data));
      })
      .catch((err) => toast.error(err.response.data?.message))
      .finally((_) => setLoading(false));
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <Row>
        {/* Left side - user data */}
        <Col xs="12" md="12" lg="3">
          <Card>
            <CardBody className="p-4">
              <div className="text-center mt-4">
                <img
                  src={user?.gender?.toLowerCase() === 'female' ? female : male}
                  className="rounded-circle"
                  width="150"
                  alt=""
                />
                <CardTitle tag="h4" className="mt-2 mb-0">
                  {user.name}
                </CardTitle>
                <CardSubtitle className="text-muted">{user.role.split('_').join(' ')}</CardSubtitle>
              </div>
            </CardBody>
            <CardBody className="border-top p-4">
              <div>
                <CardSubtitle className="text-muted fs-5">Email address</CardSubtitle>
                <CardTitle tag="h5">{user.email}</CardTitle>
              </div>
              {/* <CardSubtitle className="text-muted fs-5 mt-3 mb-2">Social Profile</CardSubtitle>
              <div className="d-flex align-items-center gap-2">
                <Button className="btn-circle" color="info">
                  <i className="bi bi-facebook"></i>
                </Button>
                <Button className="btn-circle" color="success">
                  <i className="bi bi-twitter"></i>
                </Button>
                <Button className="btn-circle" color="danger">
                  <i className="bi bi-youtube"></i>
                </Button>
              </div> */}
            </CardBody>
          </Card>
        </Col>
        {/* Right side - Profile & Edit*/}
        <Col xs="12" md="12" lg="9">
          <Card>
            {/* Tabs */}
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab === '2' ? 'active bg-transparent' : 'cursor-pointer'}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === '3' ? 'active bg-transparent' : 'cursor-pointer'}
                  onClick={() => {
                    toggle('3');
                  }}
                >
                  Edit Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === '4' ? 'active bg-transparent' : 'cursor-pointer'}
                  onClick={() => {
                    toggle('4');
                  }}
                >
                  Change Password
                </NavLink>
              </NavItem>
            </Nav>
            {/* Tabs content */}
            <TabContent activeTab={activeTab}>
              {/* <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <div className="p-4">
                      <div className="steamline position-relative border-start ms-4 mt-0">
                        <div className="sl-item my-3 pb-3 border-bottom">
                          <div className="sl-left float-start text-center rounded-circle text-white ms-n3 me-3 bg-success">
                            <img src={img2} width="40" alt="user" className="rounded-circle" />
                          </div>
                          <div className="sl-right ps-4">
                            <div>
                              <a href="/" className="text-dark fs-4 text-decoration-none fw-bold">
                                John Doe
                              </a>
                              <span className="ms-2 text-muted">5 minutes ago</span>
                              <p className="text-muted">
                                assign a new task
                                <a href="/"> Design weblayout</a>
                              </p>
                              <Row className="ms-1">
                                <Col lg="3" md="6" className="mb-3">
                                  <img src={time1} className="img-fluid rounded" alt="" />
                                </Col>
                                <Col lg="3" md="6" className="mb-3">
                                  <img src={time2} className="img-fluid rounded" alt="" />
                                </Col>
                                <Col lg="3" md="6" className="mb-3">
                                  <img src={time3} className="img-fluid rounded" alt="" />
                                </Col>
                                <Col lg="3" md="6" className="mb-3">
                                  <img src={time4} className="img-fluid rounded" alt="" />
                                </Col>
                              </Row>
                              <div className="desc ms-3">
                                <a href="/" className="text-decoration-none text-dark me-2">
                                  2 comment
                                </a>
                                <a href="/" className="text-decoration-none text-dark me-2">
                                  <i className="bi bi-heart-fill me-2 text-danger"></i>5 Love
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sl-item my-3 pb-3 border-bottom">
                          <div className="sl-left float-start text-center rounded-circle text-white ms-n3 me-3 bg-success">
                            <img src={img3} width="40" alt="user" className="rounded-circle" />
                          </div>
                          <div className="sl-right ps-4">
                            <div>
                              <a href="/" className="text-dark fs-4 text-decoration-none fw-bold">
                                John Doe
                              </a>
                              <span className="ms-2 text-muted">5 minutes ago</span>
                              <Row className="mt-3 ms-1">
                                <Col md="3" xs="12">
                                  <img src={time1} alt="user" className="img-fluid rounded" />
                                </Col>
                                <Col md="9" xs="12">
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                                    nec odio. Praesent libero. Sed cursus ante dapibus diam.
                                  </p>
                                  <a href="/" className="btn btn-success">
                                    Design weblayout
                                  </a>
                                </Col>
                              </Row>
                              <div className="desc ms-3 mt-3">
                                <a href="/" className="text-decoration-none text-dark me-2">
                                  2 comment
                                </a>
                                <a href="/" className="text-decoration-none text-dark me-2">
                                  <i className="bi bi-heart-fill me-2 text-danger"></i>5 Love
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sl-item my-3 pb-3 border-bottom">
                          <div className="sl-left float-start text-center rounded-circle text-white ms-n3 me-3 bg-success">
                            <img src={img4} width="40" alt="user" className="rounded-circle" />
                          </div>
                          <div className="sl-right ps-4">
                            <div>
                              <a href="/" className="text-dark fs-4 text-decoration-none fw-bold">
                                John Doe
                              </a>
                              <span className="ms-2 text-muted">5 minutes ago</span>
                              <p className="mt-2 ms-3">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec
                                odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla
                                quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent
                                mauris. Fusce nec tellus sed augue semper
                              </p>
                            </div>
                            <div className="desc ms-3 mt-3">
                              <a href="/" className="text-decoration-none text-dark me-2">
                                2 comment
                              </a>
                              <a href="/" className="text-decoration-none text-dark me-2">
                                <i className="bi bi-heart-fill me-2 text-danger"></i>5 Love
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="sl-item my-3 pb-3 border-bottom">
                          <div className="sl-left float-start text-center rounded-circle text-white ms-n3 me-3 bg-success">
                            <img src={img1} width="40" alt="user" className="rounded-circle" />
                          </div>
                          <div className="sl-right ps-4">
                            <div>
                              <a href="/" className="text-dark fs-4 text-decoration-none fw-bold">
                                John Doe
                              </a>
                              <span className="ms-2 text-muted">5 minutes ago</span>
                              <div className="mt-2 ms-3">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                eiusmod tempor incididunt
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </TabPane> */}
              {/* Profile Tab */}
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <div className="p-4">
                      {/* <Row>
                        <Col md="3" xs="6" className="border-end">
                          <strong>Full Name</strong>
                          <br />
                          <p className="text-muted">Johnathan Deo</p>
                        </Col>
                        <Col md="3" xs="6" className="border-end">
                          <strong>Mobile</strong>
                          <br />
                          <p className="text-muted">(123) 456 7890</p>
                        </Col>
                        <Col md="3" xs="6" className="border-end">
                          <strong>Email</strong>
                          <br />
                          <p className="text-muted">johnathan@admin.com</p>
                        </Col>
                        <Col md="3" xs="6" className="border-end">
                          <strong>Location</strong>
                          <br />
                          <p className="text-muted">London</p>
                        </Col>
                      </Row>
                      <p className="mt-4">
                        Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim
                        justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis
                        eu pede mollis pretium. Integer tincidunt.Cras dapibus. Vivamus elementum
                        semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor
                        eu, consequat vitae, eleifend ac, enim.
                      </p>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry&apos;s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries
                      </p>
                      <p>
                        It was popularised in the 1960s with the release of Letraset sheets
                        containing Lorem Ipsum passages, and more recently with desktop publishing
                        software like Aldus PageMaker including versions of Lorem Ipsum.
                      </p> */}
                      <h4 className="font-medium">
                        <span className="fw-bold">Permission Group:</span>{' '}
                        {user?.permission_group?.name}
                        {sessionStorage.getItem('role') === 'super_admin' && 'Full-Access'}
                      </h4>
                      <hr />
                      <Row>
                        {user &&
                          user.permission_group?.permissions &&
                          Object.entries(user.permission_group.permissions).map(
                            ([key, value], ind) => {
                              if (value)
                                return (
                                  <Col sm={12} md={4} key={ind}>
                                    <h5 className="font-medium">
                                      {ind + 1 + '. '}
                                      {key
                                        .split('_')
                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                        .reverse()
                                        .join(' ')}
                                    </h5>
                                    <hr />
                                  </Col>
                                );
                            },
                          )}
                      </Row>
                      {/* <h5 className="mt-4">
                        Wordpress <span className="float-end">80%</span>
                      </h5> */}
                      {/* <Progress value={2 * 5} /> */}
                      {/* <h5 className="mt-4">
                        HTML 5 <span className="float-end">90%</span>
                      </h5>
                      <Progress color="success" value="25" />
                      <h5 className="mt-4">
                        jQuery <span className="float-end">50%</span>
                      </h5>
                      <Progress color="info" value={50} />
                      <h5 className="mt-4">
                        Photoshop <span className="float-end">70%</span>
                      </h5>
                      <Progress color="warning" value={75} /> */}
                    </div>
                  </Col>
                </Row>
              </TabPane>
              {/* Edit Profile Tab */}
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <div className="p-4">
                      <Form onSubmit={handleUpdateProfile}>
                        <FormGroup>
                          <Label>Name</Label>
                          <Input
                            required
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter your name"
                            value={name}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Email</Label>
                          <Input
                            type="email"
                            className="text-muted"
                            disabled
                            placeholder="Enter youut email address"
                            value={user.email || ''}
                          />
                        </FormGroup>
                        <Button color="primary" type="submit">
                          {loading ? <Spinner size={'sm'} /> : 'Update Profile'}
                        </Button>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              {/* Change Password Tab */}
              <TabPane tabId="4">
                <Row>
                  <Col sm="12">
                    <div className="p-4">
                      <Form onSubmit={onSubmitChangePassword}>
                        <FormGroup>
                          <Label>Old Password</Label>
                          <Input
                            type="password"
                            required
                            name="oldPassword"
                            value={changePswdData.oldPassword}
                            onChange={handleOnChangePswdData}
                            placeholder="Enter your old password"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>New Password</Label>
                          <Input
                            type="password"
                            required
                            name="newPassword"
                            value={changePswdData.newPassword}
                            onChange={handleOnChangePswdData}
                            placeholder="Enter your new password"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Confirm Password</Label>
                          <Input
                            type="password"
                            required
                            name="confirmPassword"
                            value={changePswdData.confirmPassword}
                            onChange={handleOnChangePswdData}
                            placeholder="Confirm your new password"
                          />
                        </FormGroup>
                        <Button color="primary" type="submit">
                          {loading ? <Spinner size={'sm'} /> : 'Change Password'}
                        </Button>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
