import { useEffect, useState } from 'react';
import Table from '../components/common/Table';
import AddEditComponent from '../components/common/AddEditComponent';
import axios from '../axios/axios';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const Clinics = () => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [packages, setPackages] = useState([]);
  const [images, setImages] = useState({});

  const [showModalImages, setShowModalImages] = useState(false);

  const handleCloseModalImages = () => setShowModalImages(false);

  const headers = {
    headers: {
      Authorization: sessionStorage.getItem('token'),
    },
  };

  const initialValue = {
    id: 0,
    name: '',
    email: '',
    password: '',
    phone: '',
    website: '',
    package: null,
    image: null,
    certificate: null,
    taxCard: null,
    commercialRecord: null,
  };

  const [Formvalue, setFormvalue] = useState(initialValue);

  useEffect(() => {
    setLoading(true);
    axios
      .get('package', headers)
      .then((res) =>
        setPackages(res.data?.data?.map((one) => ({ label: one.name, value: one.id }))),
      )
      .catch((err) => toast.error(err.response?.data?.message))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showImages = (one) => {
    setShowModalImages(true);
    setImages(one);
  };

  // console.log(images);

  const columns = [
    {
      name: 'Name',
      value: 'name',
      type: 'text',
      placeholder: 'Enter name',
    },
    {
      name: 'Email',
      value: 'email',
      type: 'email',
      placeholder: 'Enter email address',
      disableEdit: true,
    },
    {
      name: 'phone',
      value: 'phone',
      type: 'tel',
      placeholder: 'Enter phone',
    },
    {
      name: 'Website',
      value: 'website',
      type: 'url',
      placeholder: 'Enter website url',
    },
    {
      name: 'Image',
      value: 'image',
      type: 'file',
    },
    {
      name: 'Certificate',
      value: 'certificate',
      type: 'file',
    },
    {
      name: 'Tax Card',
      value: 'taxCard',
      type: 'file',
    },
    {
      name: 'Commercial Record',
      value: 'commercialRecord',
      type: 'file',
    },
    {
      name: 'Package',
      value: 'package',
      type: 'select',
      options: packages,
    },
  ];

  const tableColumns = [
    {
      name: 'Name',
      value: 'name',
    },
    {
      name: 'Email',
      value: 'email',
    },
    {
      name: 'phone',
      value: 'phone',
    },
    {
      name: 'Website',
      value: 'website',
      tableType: 'url',
      linkLabel: 'Go to Website',
      openInNewWindow: true,
    },
    {
      name: 'Image',
      value: 'image',
      tableType: 'image',
    },
    {
      name: 'Package',
      value: 'package',
    },
  ];

  return (
    <>
      {/* Add New Clinic */}
      <AddEditComponent
        apiUrl={'clinic'}
        title={'Clinic'}
        initialValue={initialValue}
        columns={columns}
        Formvalue={Formvalue}
        setFormvalue={setFormvalue}
        setList={setList}
        edit={edit}
        setEdit={setEdit}
        loading={loading}
        reload={true}
        submitFormData={true}
      />
      {/* Clinics List */}
      <Table
        apiUrl={'clinic'}
        title={'Clinic'}
        columns={tableColumns}
        list={list}
        setList={setList}
        allowDelete={true}
        allowEdit={true}
        Formvalue={Formvalue}
        setFormvalue={setFormvalue}
        setEdit={setEdit}
        allowExtraBtn={true}
        extraBtnFn={showImages}
      />

      <Modal isOpen={showModalImages} toggle={handleCloseModalImages}>
        <ModalHeader toggle={handleCloseModalImages} className="fw-bold">
          {images?.name}
        </ModalHeader>
        <ModalBody>
          <img className="w-100" src={images?.certificate} alt="certificate" />
          <img className="w-100 my-3" src={images?.commercial_record} alt="commercial_record" />
          <img className="w-100 my-3" src={images?.tax_card} alt="tax_card" />
        </ModalBody>
      </Modal>
    </>
  );
};

export default Clinics;
